.App {
  text-align: center;
}

.App-logo {
  height: 40vmin; 
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* DECLARATIONS */
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap"); */

/* ROOT VARIABLES */
:root {
  --color-primary: #6ecc15;
  --color-danger: #ff7782;
  --color-success: #41f1b6;
  --color-warning: #ffbb55;
  --color-white: #fff;
  --color-info-dark: #7d8da1;
  --color-info-light: #dce1eb;
  --color-info-light2: #c5cddb;
  --color-dark: #363949;
  --color-light: rgba(132, 139, 200, 0.18);
  --color-primary-variant: #111e88;
  --color-dark-variant: #677483;
  --color-background: #f6f6f9;

  --card-border-radius: 0rem;
  --border-radius-1: 0.4rem;
  --border-radius-2: 0.8rem;
  --border-radius-3: 1.2rem;

  --card-padding: 1.8rem;
  --padding-1: 1.2rem;

  --box-shadow: 0 2rem 3rem var(--color-light);
}

/* DARK THEME VARIABLES */
.dark-theme-variables {
  --color-background: #181a1e;
  --color-white: #202528;
  --color-dark: #edeffd;
  --color-dark-variant: #a3bdcc;
  --color-light: rgba(0, 0, 0, 0.4);
  --box-shadow: 0 2rem 3rem var(--color-light);
}

/* STYLES */
* {
  margin: 0;
  padding: 0;
  outline: 0;
  appearance: none;
  border: 0;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
}
html {
  font-size: 14px;
}
:local(body) {
  width: 100vw;
  height: 100vh;
  font-family: poppins, sans-serif;
  font-size: 0.88rem;
  background: var(--color-background);
  user-select: none;
  overflow-x: hidden;
  color: var(--color-dark);
}
.container {
  display: grid;
  width: 96%;
  gap: 1.8rem;
  grid-template-columns: 14rem auto 0rem;
}
a {
  color: var(--color-dark);
}
img {
  display: block;
  width: 100%;
}
h1 {
  font-weight: 800;
  font-size: 1.8rem;
}
h2 {
  font-size: 1.4rem;
}
h3 {
  font-size: 0.87rem;
}
h4 {
  font-size: 0.8rem;
}
h5 {
  font-family: 0.77rem;
}
small {
  font-size: 0.75rem;
}
.profilephoto {
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 50%;
  overflow: hidden;
}
.textmuted {
  color: var(--color-info-dark);
}
p {
  color: var(--color-dark-variant);
}

.MuiSelect-nativeInput {
  color: var(--color-dark-variant);
}

select {
  color: var(--color-dark);
  font-weight: 100px;
}

b {
  color: var(--color-dark);
}
.primary {
  color: var(--color-primary);
}
.danger {
  color: var(--color-danger);
}
.success {
  color: var(--color-success);
}
.warning {
  color: var(--color-warning);
}

/* START ASIDE */
aside {
  height: 100vh;
}
aside .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
  margin-left: 2rem;

}
aside .logo {
  display: flex;
  gap: 0.8rem;
  margin-left: 2rem;
}
aside .logo img {
  margin-left: 2rem;
  width: 2rem;
  height: 2rem;
}
aside .close {
  display: none;
}

/* START SIDEBAR */
aside .sidebar {
  display: flex;
  flex-direction: column;
  height: auto;
  position: relative;
  top: 3rem;
}
aside h3 {
  font-weight: 500;
}
aside p h3 {
  font-weight: 500;
  margin-left: 2rem;
  color: var(--color-info-light);
}
aside .sidebar a {
  display: flex;
  color: var(--color-info-dark);
  margin-left: 2rem;
  gap: 1rem;
  align-items: center;
  position: relative;
  height: 3.7rem;
  transition: all 300ms ease;
}
aside .sidebar a span {
  font-size: 1.6rem;
  transition: all 300ms ease;
}
aside .sidebar a:last-child {
  position: relative;
  height: 5rem;
  bottom: 1rem;
  width: 100%;
}
aside .sidebar a.active {
  background: var(--color-light);
  color: var(--color-primary);
  margin-left: 0;
}
aside .sidebar a.active:before {
  content: "";
  width: 6px;
  height: 100%;
  background: var(--color-primary);
}
aside .sidebar a.active span {
  color: var(--color-primary);
  margin-left: calc(1rem - 6px);
}
aside .sidebar a:hover {
  color: var(--color-primary);
}
aside .sidebar a:hover span {
  margin-left: 1rem;
}
aside .sidebar .message-count {
  background: var(--color-danger);
  color: var(--color-white);
  padding: 2px 10px;
  font-size: 11px;
  border-radius: var(--border-radius-1);
}
/* END SIDEBAR */
/* END ASIDE */

/* START MAIN */
main {
  margin-top: 1.4rem;
}

/* START DATE INPUT */
main .date {
  display: inline-block;
  background: var(--color-light);
  border-radius: var(--border-radius-1);
  margin-top: 1rem;
  padding: 0.5rem 1.6rem;
}
main .date input[type="date"] {
  background: transparent;
  color: var(--color-dark);
}
/* END DATE INPUT */

/* START Div normalon */
main .fulldiv {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.6rem;
}

main .fulldiv > div {
  background: var(--color-white);
  padding: var(--card-padding);
  border-radius: var(--card-border-radius);
  margin-top: 1rem;
  box-shadow: var(--box-shadow);
  transition: all 300ms ease;
}

main .fulldiv input {
  background: var(--color-white);
  color: var(--color-dark);
  /* padding: var(--card-padding);
  border-radius: var(--card-border-radius); */
  margin-top: 1rem;
  /* box-shadow: var(--box-shadow); */
  transition: all 300ms ease;
  margin: 20px;
}

main .fulldiv > div:hover {
  cursor: pointer;
  box-shadow: none;
}
/* main .fulldiv > div span {
  background: var(--color-primary);
  padding: 0.5rem;
  border-radius: 50%;
  color: var(--color-white);
  font-size: 2rem;
} */
main .fulldiv > div.expenses span {
  background: var(--color-danger);
}
main .fulldiv > div.income span {
  background: var(--color-success);
}
main .fulldiv > div .middle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
main .fulldiv h3 {
  margin: 1rem 0 0.6rem;
  font-size: 1rem;
}
main .fulldiv .progress {
  position: relative;
  width: 92px;
  height: 92px;
  border-radius: 50%;
}
main .fulldiv svg {
  width: 7rem;
  height: 7rem;
}
main .fulldiv svg circle {
  fill: none;
  stroke: var(--color-primary);
  stroke-width: 14;
  stroke-linecap: round;
  transform: translate(5px, 5px);
  stroke-dasharray: 110;
  stroke-dashoffset: 92;
}
main .fulldiv .sales svg circle {
  stroke-dashoffset: -30;
  stroke-dasharray: 200;
}
main .fulldiv .expenses svg circle {
  stroke-dashoffset: 20;
  stroke-dasharray: 80;
}
main .fulldiv .income svg circle {
  stroke-dashoffset: 35;
}
main .fulldiv .progress .number {
  position: absolute;
  top: -2px;
  left: -2px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
main .fulldiv small {
  margin-top: 1.6rem;
  display: block;
}
/* END Div normalon */

/* START INSIGHTS */
main .insights {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.6rem;
}
main .insights > div {
  background: var(--color-white);
  padding: var(--card-padding);
  border-radius: var(--card-border-radius);
  margin-top: 1rem;
  box-shadow: var(--box-shadow);
  transition: all 300ms ease;
}
main .insights > div:hover {
  cursor: pointer;
  box-shadow: none;
}
main .insights > div span {
  background: var(--color-primary);
  padding: 0.5rem;
  border-radius: 50%;
  color: var(--color-white);
  font-size: 2rem;
}
main .insights > div.expenses span {
  background: var(--color-danger);
}
main .insights > div.income span {
  background: var(--color-success);
}
main .insights > div .middle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
main .insights h3 {
  margin: 1rem 0 0.6rem;
  font-size: 1rem;
}
main .insights .progress {
  position: relative;
  width: 92px;
  height: 92px;
  border-radius: 50%;
}
main .insights svg {
  width: 7rem;
  height: 7rem;
}
main .insights svg circle {
  fill: none;
  stroke: var(--color-primary);
  stroke-width: 14;
  stroke-linecap: round;
  transform: translate(5px, 5px);
  stroke-dasharray: 110;
  stroke-dashoffset: 92;
}
main .insights .sales svg circle {
  stroke-dashoffset: -30;
  stroke-dasharray: 200;
}
main .insights .expenses svg circle {
  stroke-dashoffset: 20;
  stroke-dasharray: 80;
}
main .insights .income svg circle {
  stroke-dashoffset: 35;
}
main .insights .progress .number {
  position: absolute;
  top: -2px;
  left: -2px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
main .insights small {
  margin-top: 1.6rem;
  display: block;
}
/* END INSIGHTS */

/* START RECENT ORDERS */
main .recentorders {
  margin-top: 0rem;
}
main .recentorders h2 {
  margin-bottom: 0.8rem;
}
main .recentorders table {
  background: var(--color-white);
  width: 100%;
  border-radius: var(--card-border-radius);
  padding: var(--card-padding);
  /* text-align: center; */
  box-shadow: var(--box-shadow);
  transition: all 300ms ease;
}
main .recentorders table:hover {
  cursor: pointer;
  box-shadow: none;
}
main table tbody td {
  height: 2.8rem;
  /* border-bottom: 1px solid var(--color-light); */
  color: var(--color-dark-variant);
}
main table tbody tr:last-child td {
  border: none;
}
main .recentorders a {
  text-align: center;
  display: block;
  margin: 1rem auto;
  color: var(--color-primary);
}
/* END RECENT ORDERS */

/* START RIGHT SECTION */
.right {
  margin-top: 1.4rem;
}
.right .top {
  display: flex;
  justify-content: end;
  gap: 2rem;
}

.right .top button {
  display: none;
}


.right .theme-toggler {
  background: var(--color-light);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.6rem;
  width: 4.2rem;
  cursor: pointer;
  border-radius: var(--border-radius-1);
}
.right .theme-toggler span {
  font-size: 1.2rem;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.right .theme-toggler span.active {
  background: var(--color-primary);
  color: white;
  border-radius: var(--border-radius-1);
}
.right .top .profile {
  display: flex;
  gap: 2rem;
  text-align: right;
}

/* START RECENT UPDATES */
.right .recent-updates {
  margin-top: 1rem;
}
.right .recent-updates h2 {
  margin-bottom: 0.8rem;
}
.right .recent-updates .updates {
  background: var(--color-white);
  padding: var(--card-padding);
  border-radius: var(--card-border-radius);
  box-shadow: var(--box-shadow);
  transition: all 300ms ease;
}
.right .recent-updates .updates:hover {
  cursor: pointer;
  box-shadow: none;
}
.right .recent-updates .updates .update {
  display: grid;
  grid-template-columns: 2.6rem auto;
  gap: 1rem;
  margin-bottom: 1rem;
}
/* END RECENT UPDATES */

/* START SALES ANALYTICS */
.right .sales-analytics {
  margin-top: 2rem;
}
.right .sales-analytics h2 {
  margin-bottom: 0.8rem;
}
.right .sales-analytics .item {
  background: var(--color-white);
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.7rem;
  padding: 1.4rem var(--card-padding);
  border-radius: var(--border-radius-3);
  box-shadow: var(--box-shadow);
  transition: all 300ms ease;
}
.right .sales-analytics .item:hover {
  cursor: pointer;
  box-shadow: none;
}
.right .sales-analytics .item .right {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin: 0;
  width: 100%;
}
.right .sales-analytics .item .icon {
  padding: 0.6rem;
  color: var(--color-white);
  border-radius: 50%;
  background: var(--color-primary);
  display: flex;
}
.right .sales-analytics .item.offline .icon {
  background: var(--color-danger);
}
.right .sales-analytics .item.customers .icon {
  background: var(--color-success);
}
.right .sales-analytics .add-product {
  background: transparent;
  border: 2px dashed var(--color-primary);
  color: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
}
.right .sales-analytics .add-product:hover {
  background: var(--color-primary);
  color: white;
}
.right .sales-analytics .add-product div {
  display: flex;
  align-items: center;
  gap: 0.6rem;
}
.right .sales-analytics .add-product h3 {
  font-weight: 600;
}
/* END SALES ANALYTICS */
/* END RIGHT SECTION */

/* START MEDIA QUERIES */
/* TABLETS AND SMALL LAPTOPS */
@media screen and (max-width: 1200px) {
  .container {
    width: 94%;
    grid-template-columns: 7rem auto 0rem;
  }
  aside .logo h2 {
    display: none;

  }
  aside .sidebar h3 {
    display: none;
  }
  aside .sidebar a {
    width: 5.6rem;
  }
  aside .sidebar a:last-child {
    position: relative;
    margin-top: 1.8rem;
  }
  main .insights {
    grid-template-columns: 1fr;
    gap: 0;
  }
  main .recentorders {
    width: 94%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin: 2rem 0 0 8.8rem;
  }
  main .recentorders table {
    width: 83vw;
  }
  main .recentorders table thead tr th:first-child,
  main .recentorders table thead tr th:last-child {
    /* display: none; */
  }
  main .recentorders table tbody tr td:first-child,
  main .recentorders table tbody tr td:last-child {
    /* display: none; */
  }
}

/* SMALL TABLETS AND MOBILE */
@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    grid-template-columns: 1fr;
  }
  aside {
    position: fixed;
    left: -100%;
    background: var(--color-white);
    width: 18rem;
    z-index: 3;
    box-shadow: 1rem 3rem 4rem var(--color-light);
    height: 100vh;
    padding-right: var(--card-padding);
    display: none;
    animation: showMenu 400ms ease forwards;
  }
  @keyframes showMenu {
    to {
      left: 0;
    }
  }
  aside .logo {
    margin-left: 1rem;
  }
  aside .logo h2 {
    display: inline;
  }
  aside .sidebar h3 {
    display: inline;
  }
  aside .sidebar a {
    width: 100%;
    height: 3.4rem;
  }
  aside .sidebar a:last-child {
    position: absolute;
    bottom: 5rem;
  }
  aside .close {
    display: inline-block;
    cursor: pointer;
  }
  main {
    margin-top: 8rem;
    padding: 0 1rem;
  }
  main .recentorders {
    position: relative;
    margin: -4rem 0 0 0;
    width: 100%;
  }
  main .recentorders table {
    width: 100%;
    margin: 0;
  }
  .right {
    width: 94%;
    /* margin: 0 auto 4rem; */
  }
  .right .top {
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    padding: 0 0.8rem;
    height: 4.6rem;
    background: var(--color-white);
    width: 100%;
    margin: 0;
    z-index: 2;
    box-shadow: 0 1rem 1rem var(--color-light);
  }
  .right .top .theme-toggler {
    width: 4.4rem;
    position: absolute;
    left: 66%;
  }
  .right .top .profile .info {
    display: none;
  }
  .right .top button {
    display: inline-block;
    background: transparent;
    cursor: pointer;
    color: var(--color-dark);
    position: absolute;
    left: 1rem;
  }
  .right .top button span {
    font-size: 2rem;
  }
}
/* END MEDIA QUERIES */

/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z11lFc-K.woff2)
    format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8,
    U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1JlFc-K.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1xlFQ.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecmNE.woff2)
    format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8,
    U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJnecmNE.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecg.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z11lFc-K.woff2)
    format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8,
    U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1JlFc-K.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1xlFQ.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z11lFc-K.woff2)
    format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8,
    U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1JlFc-K.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFQ.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z11lFc-K.woff2)
    format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8,
    U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1JlFc-K.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1xlFQ.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDD4Z11lFc-K.woff2)
    format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8,
    U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDD4Z1JlFc-K.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDD4Z1xlFQ.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

#row__posters {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 20px;
}

#row__posters::-webkit-scrollbar {
  display: none;
}

#row__poster {
  width: 100%;
  object-fit: contain;
  max-height: 100px;
  margin-right: 10px;
  transition: transform 450ms;
}

#row__posterLarge {
  max-height: 250px;
}

#row__posterLarge:hover {
  transform: scale(1.09);
}

#row__poster:hover {
  transform: scale(1.08);
}
